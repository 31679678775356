
import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { empty } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AlertService } from '../../../../../global/info-alert/alert.service';
import { VistoriaVO } from '../../../../../global/models/vistoriaVO';
import { datePickerOptions } from '../../../../../global/utils/myDatePickerOptions';
import { VistoriaService } from '../../services/vistoria.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IMyDateModel } from 'angular-mydatepicker';
import { IEtpMyDateModel } from '../../../../../global/my-date-picker/IMyDateModel';

@Component({
  selector: 'etp-vistoria',
  templateUrl: './vistoria.component.html',
  styleUrls: ['./vistoria.component.scss']
})
export class VistoriaComponent implements OnInit {

  constructor(
    private vistoriaService: VistoriaService,
    private alertService: AlertService,
    private loader: NgxSpinnerService,
    private route: ActivatedRoute) {
  }

  vistoriaList: VistoriaVO[] = [];
  vistoriaListPaginacao: VistoriaVO[] = [];

  //Critérios de Pesquisa
  criterioCodigoMunicipal: number = null;
  criterioDataRealizacao: IEtpMyDateModel = null;
  criterioImovelNomePredio: string = null;
  // mostrar pesquisa
  isResultadoHidden: boolean = true;
  // paginacao
  qtdPorPagina: number = 10;

  optionalParams: any;

  ngOnInit() {
    this.route.queryParamMap.pipe(switchMap(params => {
      let codigoMunicipal = +params.get('codeqp');
      let data: any = params.get('dtr');
      if (data) {
        data = new Date(+data);
      }
      return this.pesquisar(codigoMunicipal, data);
    })).subscribe();
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }

    let dataRealizacao = this.criterioDataRealizacao ? this.criterioDataRealizacao.jsdate : null;
    this.isResultadoHidden = true;

    this.pesquisar(this.criterioCodigoMunicipal, dataRealizacao, this.criterioImovelNomePredio).subscribe();
  }

  paginar($event: any) {
    let pagina = $event - 1;
    let inicio = pagina * this.qtdPorPagina;
    let final = inicio + this.qtdPorPagina;
    this.vistoriaListPaginacao = this.vistoriaList.slice(inicio, final);
  }

  private pesquisar(codigo?: number, data?: Date, nome?: string) {

    if (!codigo && !data && !nome) {
      return empty();
    }

    this.loader.show();

    this.optionalParams = {
      codeqp: codigo || undefined,
      dtr: data && data.getTime() || undefined
    };

    return this.vistoriaService.obtemVistoriaPesquisaEmpresa(codigo, nome, data)
      .pipe(
        tap(resultado => {
          this.vistoriaList = resultado
          this.vistoriaListPaginacao = resultado.slice(0, this.qtdPorPagina);
        },
          error => {
            this.alertService.criaAlerta(error, 'erro');
            this.loader.hide();
          },
          () => {
            this.isResultadoHidden = false;
            this.loader.hide();
          }));

  }
}
