<form class="form-horizontal" #vistoriaForm="ngForm" (ngSubmit)="persisteVistoria()" novalidate>

  <div class="panel panel-primary">
    <div class="panel-heading">Informações da Vistoria</div>

    <div class="panel-body">
      <div class="form-group">
        <label for="inputDataRealizacao" class="col-sm-2 control-label">Data Realização</label>
        <div class="col-sm-4">
          <my-date-picker name="inputDataRealizacao" [(ngModel)]="vistoria.dataRealizacao" #inputDataRealizacao="ngModel"></my-date-picker>
        </div>
      </div>

      <div class="form-group">
        <label for="inputCodMunicipal" class="col-sm-2 control-label">Código Municipal</label>
        <div class="col-sm-4">
          <input type="text" class="form-control" name="inputCodMunicipal" [(ngModel)]="vistoria.codigoMunicipal"
            #inputCodMunicipal="ngModel" />
        </div>
      </div>

      <div class="form-group">
        <label for="inputSituacao" class="col-sm-2 control-label">Situação Equipamento</label>
        <div class="col-sm-4">
          <select class="form-control" id="inputSituacao" [(ngModel)]="vistoria.situacaoEquipamento"
            name="inputSituacao" required #inputSituacao="ngModel">
            <option value="" disabled selected>Selecione...</option>
            <option *ngFor="let situacao of situacaoVistoriaList" [value]="situacao">{{situacao}}</option>
          </select>
          <div *ngIf="inputSituacao.errors && (inputSituacao.dirty)" class="alert alert-danger">
            <div [hidden]="!inputSituacao.errors.required">
              <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>Situação inválida!
            </div>
          </div>
        </div>

        <label for="inputTipo" class="col-sm-2 control-label">Tipo Vistoria</label>
        <div class="col-sm-4">
          <select class="form-control" id="inputTipo" [(ngModel)]="vistoria.tipoVistoria" name="inputTipo" required
            #inputTipo="ngModel">
            <option value="" disabled selected>Selecione...</option>
            <option *ngFor="let tipo of tipoVistoriaList" [value]="tipo">{{tipo}}</option>
          </select>
          <div *ngIf="inputTipo.errors && (inputTipo.dirty)" class="alert alert-danger">
            <div [hidden]="!inputTipo.errors.required">
              <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>Tipo inválido!
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="inputDescricao" class="col-sm-2 control-label">Descrição Geral Equipamento</label>
        <div class="col-sm-10">
          <textarea rows="5" [(ngModel)]="vistoria.descricaoGeralEquipamento" name="inputDescricao" class="form-control"
            id="inputDescricao" placeholder="informe a DESCRIÇÃO"></textarea>
        </div>
      </div>

      <div class="form-group">
        <label for="inputRecomendacao" class="col-sm-2 control-label">Recomendações</label>
        <div class="col-sm-10">
          <textarea rows="3" [(ngModel)]="vistoria.recomendacao" name="inputRecomendacao" class="form-control"
            id="inputRecomendacao" placeholder="informe a RECOMENDAÇÃO"></textarea>
        </div>
      </div>

      <div class="form-group">
        <label for="inputObservacao" class="col-sm-2 control-label">Observações</label>
        <div class="col-sm-10">
          <textarea rows="3" [(ngModel)]="vistoria.observacao" name="inputObservacao" class="form-control"
            id="inputObservacao" placeholder="informe a OBSERVAÇÃO"></textarea>
        </div>
      </div>

      <div>
        <div class="checkbox">
          <label>
            <input type="checkbox" [(ngModel)]="vistoria.recusaResponsavel" name="checkRecusaResponsavel"
              id="checkRecusaResponsavel">Recusado pelo Resp.
          </label>
        </div>

        <div class="form-group">
          <label for="inputMotivoRecusaResp" class="col-sm-2 control-label">Motivo Recusa Resp.</label>
          <div class="col-sm-10">
            <textarea type="text" rows="2" [disabled]="!vistoria.recusaResponsavel"
              [(ngModel)]="vistoria.motivoRecusaResponsavel" name="inputMotivoRecusaResp" class="form-control"
              id="inputMotivoRecusaResp" placeholder="informe o MOTIVO DA RECUSA DO RESPONSÁVEL, se houver"></textarea>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="inputVistoriador" class="col-sm-2 control-label">Vistoriador</label>
        <div class="col-sm-10">
          <input type="text" [(ngModel)]="vistoria.vistoriador" name="inputVistoriador" class="form-control"
            id="inputVistoriador" required minlength="4" maxlength="50" placeholder="informe o Nome"
            #inputVistoriador="ngModel">
          <div *ngIf="inputVistoriador.errors && (inputVistoriador.dirty)" class="alert alert-danger">
            <div [hidden]="!inputVistoriador.errors.required">
              <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>Favor informar o nome
            </div>
            <div [hidden]="!inputVistoriador.errors.minlength">
              <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>O nome deve ter no mínimo 4 caracteres.
            </div>
            <div [hidden]="!inputVistoriador.errors.maxlength">
              <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>O nome não pode ter mais de 50
              caracteres.
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="panel-footer clearfix ">
      <div class="pull-right">
        <button type="button" class="btn" [routerLink]="['/vistorias']"
          queryParamsHandling='preserve'>Voltar</button>
        <button type="submit" class="btn btn-primary" [disabled]="!vistoriaForm.form.valid">Salvar</button>
        <ngx-spinner type="ball-clip-rotate-multiple" size="medium">
          <p class="loading">Carregando...</p>
        </ngx-spinner>
      </div>
    </div>

  </div>
</form>
