import { Component } from '@angular/core';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { etpRoles } from '../../../global/models/roles';
import { AlertService } from '../../../global/info-alert/alert.service';
import { EmpresaService } from './services/empresa.service';

@Component({
  selector: 'etp-empresa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private kc: KeycloakService, private es: EmpresaService, private as: AlertService) {
  }

  title = 'etpelevadores-empresa';
  nome = "Empresa Hardcode";
  role = etpRoles;

  ngOnInit(): void {
    this.kc.getUser().subscribe(usuario => {
      this.nome = usuario.fullName
    });

    this.es.verificarCadastroEmpresa().subscribe(
      ok => {
        if(!ok){
          this.as.criaAlerta(`Empresa não foi pré cadastrada ou o e-mail não é identico ao pré-cadastro`);
        }
      },
      e => {
        this.as.criaAlerta(JSON.stringify(e), 'erro');
      },
      () => { }
    );
  }

  logout() {
    this.kc.logout();
  }

}
