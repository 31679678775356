import { get } from "lodash";
import { IMyDateModel } from "angular-mydatepicker";

export function dateToMyDateModel(Data: Date) {
  if (Data != null && Data instanceof Date) {
    return {
      isRange: false,
      singleDate: { jsDate: Data }
    };
  }
  return undefined;
}
export function myDateModelToDate(Data: Date | IMyDateModel) {
  return get(Data, "singleDate.jsDate", Data);
}
