import { Component, ElementRef, forwardRef, Input, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';

@Component({
  selector: 'my-date-picker',
  templateUrl: './my-date-picker.component.html',
  styleUrls: ['./my-date-picker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MyDatePickerComponent), multi: true }
  ]
})
export class MyDatePickerComponent implements OnInit, ControlValueAccessor {

  constructor() { }

  @Input()
  options: IAngularMyDpOptions = {};

  model: IMyDateModel;
  isDisabled = false;
  timeoutRef: any;

  ngOnInit() {
  }

  writeValue(obj: any): void {
    this.model = obj;
  }

  registerOnChange(fn: any): void {
    this.onChanges = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled && this.timeoutRef == null) {
      this.timeoutRef = setTimeout(() => {
        this.isDisabled = isDisabled
      }, 2000);
    }
    if(isDisabled === false){
      this.isDisabled = isDisabled;
    }
  }

  onDateChanged(date: IMyDateModel) {
    const dataAdapter: any = { ...date };
    if (!date.isRange) {
      dataAdapter.jsdate = date.singleDate.jsDate;
    }
    this.writeValue(dataAdapter);
    this.onChanges(dataAdapter);
    this.onTouched();
  }

  private onChanges: any = () => { }

  private onTouched: any = () => { }

}
