import { Component, EventEmitter, OnInit, TemplateRef } from '@angular/core';
import { AuthUser, KeycloakService } from '@procempa/ngx-keycloak';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { humanizeBytes, UploaderOptions, UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';
import { switchMap, tap } from 'rxjs/operators';
import { ExceptionBean } from '../../../../../global/models/ExceptionBean';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../../../../global/info-alert/alert.service';

@Component({
  selector: 'etp-empresa-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  qtdPorPagina: number = 10;
  // Upload File
  ngxFile: UploadFile;
  uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader;
  humanizeBytes: Function;
  options: UploaderOptions = { concurrency: 1, maxUploads: 1, allowedContentTypes: ['text/csv', 'application/vnd.ms-excel'] }
  // retornos
  retornoErroList: ExceptionBean[];
  retornoErroListTela: ExceptionBean[];
  isArquivoValido: boolean;
  situacaoEnvio = 'Não enviado';
  //tela
  tipoArquivo: string = "";
  isValidando = false;

  modalRef: BsModalRef;

  private API_URL = environment.apiUrl;

  constructor(private keycloakService: KeycloakService, private modalService: BsModalService, private alertService: AlertService) {
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.modalService.onHidden.subscribe(() => this.modalRef = undefined);
  }

  get hasFile() {
    return this.ngxFile && this.ngxFile.id;
  }

  resetarSituacaoEnvio() {
    this.situacaoEnvio = 'Não enviado';
  }

  onUploadOutput(output: UploadOutput): void {

    if (output.type === 'allAddedToQueue') {
    } else if (output.type === 'rejected' && output.file) {
      alert('Arquivo inválido.');

    } else if (output.type === 'addedToQueue') {
      if (output.file.size < 10000000) {
        this.ngxFile = output.file;
        this.isArquivoValido = false;
        this.limparAreaErros();
        this.resetarSituacaoEnvio();
      }

    } else if (output.type === 'uploading') {
      this.ngxFile = output.file;
      this.isValidando = true;

    } else if (output.type === 'removed') {
      this.ngxFile = undefined;

    } else if (output.type === 'dragOver') { // drag over event
    } else if (output.type === 'dragOut') { // drag out event
    } else if (output.type === 'drop') { // on drop event
    } else if (output.type === 'done') {

      if (output.file.responseStatus == 0) {
        this.isArquivoValido = false;
        this.retornoErroList = [{ codigo: 'CLIENT', id: 'Erro no servidor', mensagem: 'Erro interno inesperado' }];
        this.retornoErroListTela = this.retornoErroList.slice(0, this.qtdPorPagina);

      } else if (output.file.responseStatus == 500) {
        const response = output.file.response;
        this.isArquivoValido = false;
        if (Array.isArray(response)) {
          const eb = response[0];
          const msg = `${eb.codigo}: ${eb.mensagem} ${eb.id ? `Valor: ${eb.id}` : ""}`;
          this.alertService.criaAlerta(msg, 'erro');

          this.retornoErroList = [{ codigo: 'CLIENT', id: 'Erro no servidor', mensagem: msg }];
          this.retornoErroListTela = this.retornoErroList.slice(0, this.qtdPorPagina);
        }
        // sucesso
      } else if (output.file.responseStatus == 200) {
        this.isArquivoValido = true;
        this.situacaoEnvio = 'Enviado com sucesso';
        this.limparAreaErros();

      } else {
        this.isArquivoValido = false;
        this.retornoErroList = Array.isArray(output.file.response) ? output.file.response : [output.file.response];
        this.retornoErroListTela = this.retornoErroList.slice(0, this.qtdPorPagina);
      }

      this.isValidando = false;
      this.uploadInput.emit({ type: 'remove', id: output.file.id });
    }
  }

  /**
   * Faz sumir a área de erros
   */
  private limparAreaErros() {
    this.retornoErroList = undefined;
    this.retornoErroListTela = undefined;
  }

  openModal(template: TemplateRef<any>) {
    if (!this.modalRef) {
      this.modalRef = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
    }
  }

  closeModal(template: TemplateRef<any>) {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  startUpload(): void {

    const event: UploadInput = {
      type: 'uploadFile',
      method: 'POST',
      id: this.ngxFile.id,
      fieldName: this.tipoArquivo,
      fileIndex: this.ngxFile.fileIndex,
      file: this.ngxFile,
      data: { size: `${this.ngxFile.size}` }
    }

    const obs = (usuario: AuthUser) => {
      return this.keycloakService.getToken().pipe(tap(token => {
        event.headers = { 'Authorization': `Bearer ${token}` };

        let url: string;
        if (this.tipoArquivo === 'equipamento') {
          url = `${this.API_URL}/equipamento/upload/${usuario.username}`;
        } else if (this.tipoArquivo === 'vistoria') {
          url = `${this.API_URL}/vistoria/upload/${usuario.username}`;
        } else if (this.tipoArquivo === 'manutencao') {
          url = `${this.API_URL}/empresa/upload/${usuario.username}`;
        } else if (this.tipoArquivo === 'encerramento') {
          url = `${this.API_URL}/encerramento/upload/${usuario.username}`;
        }
        event.url = url;
        this.uploadInput.emit(event);
      }))
    }

    this.keycloakService
      .getUser()
      .pipe(switchMap(obs))
      .subscribe();
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  paginar($event: number) {
    let pagina = $event - 1;
    let inicio = pagina * this.qtdPorPagina;
    let final = inicio + this.qtdPorPagina;
    this.retornoErroListTela = this.retornoErroList.slice(inicio, final);
  }

  isValidacaoComErro(): boolean {
    return !this.isArquivoValido && !!this.retornoErroList;
  }

}
