<header>
  <div class="container">
    <div class="row">
      <div class="nome-sistema col-sm-7">
        <h2>CCEME - Controle e Cadastro de Empresas de Manutenção de Elevadores</h2>
        <br>
        <h3>Secretaria Municipal de Meio Ambiente e Sustentabilidade</h3>
      </div>
      <div class="brasao col-sm-5 text-right">
        <a href="http://www2.portoalegre.rs.gov.br/spm/" target="_blank">
          <img class="hidden-xs" src="/assets/images/brasao_pmpa.svg" alt="Secretaria Municipal de Urbanismo"
            title="Secretaria Municipal de Urbanismo">
        </a>
      </div>
    </div>
  </div>
</header>

<nav class="navbar navbar-default">
  <div class="container">
    <ul class="nav navbar-nav nav-tabs">
      <li routerLinkActive="active"><a routerLink="./equipamentos">Equipamento</a></li>
      <li routerLinkActive="active"><a routerLink="./vistorias">Vistoria</a></li>
      <li routerLinkActive="active"><a routerLink="./uploads">Upload</a></li>
    </ul>

    <ul class="nav navbar-nav navbar-right">
      <li class="dropdown" dropdown>
        <a dropdownToggle class="btn dropdown-toggle">{{nome}} <span class="caret"></span></a>
        <ul *dropdownMenu class="dropdown-menu">
          <li role="menuitem">
            <a class="btn dropdown-item" (click)="logout()">Sair <span class="glyphicon glyphicon-log-out"
                aria-hidden="true"></span>
            </a>
          </li>
        </ul>
      </li>
    </ul>

  </div>
</nav>

<div class="container">
  <etp-global-info-alert></etp-global-info-alert>
  <router-outlet></router-outlet>
</div>
