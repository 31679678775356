<!-- add the following snippet inside your template -->
<div class="input-group">
  <!-- input box -->
  <input class="form-control" [disabled]="isDisabled" type="text" name="mydate" placeholder="Selecione uma data..." angular-mydatepicker
    #dp="angular-mydatepicker" [(ngModel)]="model" [options]="options" [locale]="'pt-br'"
    (dateChanged)="onDateChanged($event)" />

  <div class="input-group-btn">
    <!-- clear date button -->
    <button type="button" class="btn btn-secondary" *ngIf="model" (click)="dp.clearDate()">
      <i class="glyphicon glyphicon-remove"></i>
    </button>
    <!-- toggle calendar button -->
    <button type="button" class="btn btn-secondary" (click)="dp.toggleCalendar()">
      <i class="glyphicon glyphicon-calendar"></i>
    </button>
  </div>
</div>
