
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { VistoriaVO } from '../../../../global/models/vistoriaVO';
import { errorHandler } from '../../../../global/utils/errorhandler';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: "root"
})
export class VistoriaService {

  constructor(private http: HttpClient) {
  }

  API_URL = environment.apiUrl + '/vistoria';

  /**
   * pesquisa as vistorias de uma empresa
   * @param codigoMunicipal
   * @param imovelNomePredio
   * @param dataRealizacao
   */
  obtemVistoriaPesquisaEmpresa(codigoMunicipal: number, imovelNomePredio: string, dataRealizacao: Date): Observable<VistoriaVO[]> {

    let httpParams = new HttpParams();

    if (dataRealizacao) {
      let data = dataRealizacao.toDateString();
      httpParams = httpParams.set('dataRealizacao', data);
    }

    if (imovelNomePredio) {
      httpParams = httpParams.set('imovelNomePrediog', imovelNomePredio);
    }

    if (codigoMunicipal) {
      httpParams = httpParams.set('codigoMunicipal', codigoMunicipal.toString());
    }

    return this.http
      .get<VistoriaVO[]>(this.API_URL + '/pesquisaVistoria/empresa', { params: httpParams })
      .pipe(catchError(errorHandler));
  }

  obtemTipoVistoriaList(): Observable<string[]> {
    return this.http
      .get<string[]>(this.API_URL + "/tipoVistoria")
      .pipe(catchError(errorHandler));
  }

  obtemSituacaoVistoriaList(): Observable<string[]> {
    return this.http
      .get<string[]>(this.API_URL + "/situacaoVistoria")
      .pipe(catchError(errorHandler));
  }

  obtemVistoriaId(id: string): Observable<VistoriaVO> {
    return this.http.get<VistoriaVO>(this.API_URL + '/detalhe/' + id)
      .pipe(catchError(errorHandler));
  }

  persisteVistoria(vistoria: VistoriaVO) {
    return this.http
      .post<VistoriaVO>(this.API_URL, vistoria)
      .pipe(catchError(errorHandler));
  }
}




