<form class="form-horizontal" #f="ngForm" (ngSubmit)="pesquisarEquipamento()">

  <p class="col-md-10">Pesquisa de Equipamento</p>

  <nav class="col-md-2">
    <a [routerLink]="['./','novo']">Novo Equipamento</a>
  </nav>

  <br>
  <br>

  <div class="panel panel-default">
    <div class="panel-heading">Critérios de Pesquisa</div>

    <div class="panel-body">

      <div class="filtros-check">
        <div class="checkbox">
          <label>
            <input type="checkbox" name="checkSemContrato" id="checkSemContrato" [(ngModel)]="criterioSemContrato">Equipamentos sem contrato
          </label>
        </div>
      </div>

      <div class="form-group">
        <label for="inputCriterioCodigoMunicipal" class="col-sm-2 control-label">Código Municipal</label>
        <div class="col-sm-4">
          <input type="text" [(ngModel)]="criterioCodigoMunicipal" class="form-control" name="inputCriterioCodigoMunicipal" id="idCriterioCodigoMunicipal"
            placeholder="Código Municipal">
        </div>
      </div>

      <div class="form-group">
        <label for="inputCriterioDataInicio" class="col-sm-2 control-label">Período de validade</label>
        <div class="col-sm-4">
          <my-date-picker [disabled]="disableDatas" name="criterioDataInicio" [(ngModel)]="criterioDataInicio"></my-date-picker>
        </div>
        <div class="col-sm-4">
          <my-date-picker [disabled]="disableDatas" name="criterioDataFim" [(ngModel)]="criterioDataFim"></my-date-picker>
        </div>
        <div class="col-sm-2">
          <span class="glyphicon glyphicon-hand-left" style="font-size: 25px; line-height: 30px;" data-toggle="tooltip" title=">> Ao informar apenas UMA DATA, lista todos os equipamentos válidos para esta data.
              >> Ao informar o PERÍODO (duas datas), lista apenas os equipamentos do período (mesma data inicial e data final).">
          </span>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label"></label>
        <div class="col-sm-10">
          <p class="text-info">
            <strong>Por favor, informe o NOME e NÚMERO do logradouro separados por vírgula. OBRIGATÓRIO!</strong>
          </p>
        </div>
      </div>
      <etp-procempa-cdl [isCompleto]="false" (onSelecionaEndereco)="receiveResults($event)"></etp-procempa-cdl>

    </div>

    <div class="panel-footer clearfix">
      <div class="pull-right">
        <button type="submit" class="btn btn-primary">Pesquisar</button>
      </div>
    </div>

  </div>

  <div class="panel panel-primary" *ngIf="contratoEquip && contratoEquip.length">
    <div class="panel-heading">
      <h3 class="panel-title">Lista de Equipamento</h3>
    </div>
    <div class="panel-body">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="col-md-2 ">Código Municipal</th>
            <th class="col-md-2 ">Data Inico</th>
            <th class="col-md-2 ">Data Fim</th>
            <th class="col-md-2 ">Tipo</th>
            <th class="col-md-4 ">Endereço</th>
          </tr>
        </thead>
        <tr class="collection-item " *ngFor="let contrato of contratoEquipTela">
          <td>
            <a routerLinkActive="active " [routerLink]="[ './', 'detalhes', contrato?.equipamentoVO?.idEquipamento] ">{{ contrato.equipamentoVO.codigoMunicipalFormatado }}</a>
          </td>
          <td>{{ contrato?.dataInicio | date:'dd/MM/yyyy' }}</td>
          <td>{{ contrato?.dataFim | date:'dd/MM/yyyy' }}</td>
          <td>{{ contrato?.equipamentoVO.tipoEquipamento }}</td>
          <td>{{ contrato?.equipamentoVO.imovelNomePredio }}</td>
        </tr>
      </table>
      <etp-global-paginar [qtdPorPagina]="qtdPorPagina" [totalRegistros]="contratoEquip?.length" [qtdAdjacentes]="3" (onPaginate)="paginar($event) "></etp-global-paginar>
    </div>
  </div>

</form>
