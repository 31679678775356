
import { Component, OnInit } from '@angular/core';
import { LogradouroCdl } from 'procempa-cdl-lib';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AlertService } from '../../../../../global/info-alert/alert.service';
import { ContratoVO } from '../../../../../global/models/contratoVO';
import { EquipamentoService } from '../../services/equipamento.service';

@Component({
  selector: 'etp-empresa-equipamento',
  templateUrl: './equipamento.component.html',
  styleUrls: ['./equipamento.component.scss']
})
export class EquipamentoComponent implements OnInit {

  constructor(
    private equipamentoService: EquipamentoService,
    private alertService: AlertService
  ) {
  }

  // Critérios de Pesquisa
  criterioCodigoMunicipal: number = null;
  criterioDataInicio: any = null;
  criterioDataFim: any = null;
  criterioSemContrato: boolean = false;

  private logradouroCdl: LogradouroCdl;

  // paginacao
  pagina: number = 0;
  qtdPorPagina: number = 10;

  // view
  contratoEquipTela: ContratoVO[];
  contratoEquip: ContratoVO[];

  ngOnInit() {
  }

  pesquisarEquipamento() {

    let cdl: number, ncdl: number;
    let di: Date, df: Date;

    if (this.criterioDataInicio) {
      di = this.criterioDataInicio.jsdate;
    }
    if (this.criterioDataFim) {
      df = this.criterioDataFim.jsdate;
    }
    if (this.logradouroCdl) {
      cdl = this.logradouroCdl.codigoLogradouro;
      ncdl = this.logradouroCdl.numero;
    }

    if (!this.criterioCodigoMunicipal && !cdl && !ncdl && !di && !df && !this.criterioSemContrato) {
      return;
    }

    if (this.criterioSemContrato) {
      return this.pesquisarSemContrato(this.criterioCodigoMunicipal, cdl, ncdl).subscribe();
    }

    return this.pesquisarComEmpresa(this.criterioCodigoMunicipal, di, df, cdl, ncdl).subscribe();
  }

  private pesquisarComEmpresa(...args): Observable<ContratoVO[]> {
    return this.equipamentoService.obtemEquipamentoPesquisaEmpresa(args[0], args[1], args[2], args[3], args[4])
      .pipe(
        tap((contratos) => {
          this.contratoEquip = contratos;
          this.contratoEquipTela = contratos.slice(0, this.qtdPorPagina);
        },
          (error) => {
            this.contratoEquip = [];
            this.contratoEquipTela = [];
            this.alertService.criaAlerta(error, 'warning');
          }));
  }

  private pesquisarSemContrato(...args): Observable<ContratoVO[]> {
    return this.equipamentoService.obtemEquipamentoPesquisaSemContrato(args[0], args[1], args[2])
      .pipe(
        tap((contratos) => {
          this.contratoEquip = contratos;
          this.contratoEquipTela = contratos.slice(0, this.qtdPorPagina);
        },
          (error) => {
            this.contratoEquip = [];
            this.contratoEquipTela = [];
            this.alertService.criaAlerta(error, 'warning');
          }));;
  }

  receiveResults(logradouroCdl: LogradouroCdl) {
    this.logradouroCdl = logradouroCdl;
  }

  paginar($event: any) {
    let pagina = $event - 1;
    let inicio = pagina * this.qtdPorPagina;
    let final = inicio + this.qtdPorPagina;
    this.contratoEquipTela = this.contratoEquip.slice(inicio, final);
  }

  get disableDatas() {
    return this.criterioSemContrato;
  }
}
