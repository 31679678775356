import { APP_BASE_HREF } from '@angular/common';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { GlobalModule } from '../../../global/global.module';
import { routes } from './app-routing.routes';
import { AppComponent } from './app.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UploadComponent } from './components/upload/upload.component';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxUploaderModule } from 'ngx-uploader';
import { EquipamentoComponent } from './components/equipamento/equipamento.component';
import { EquipamentoEdicaoComponent } from './components/equipamento/equipamento-edicao/equipamento-edicao.component';
import { VistoriaComponent } from './components/vistoria/vistoria.component';
import { VistoriaEdicaoComponent } from './components/vistoria/vistoria-edicao/vistoria-edicao.component';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { KeycloakService, NgxKeycloakModule } from '@procempa/ngx-keycloak';

@NgModule({
  declarations: [
    AppComponent,
    UploadComponent,
    EquipamentoComponent,
    EquipamentoEdicaoComponent,
    VistoriaComponent,
    VistoriaEdicaoComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    GlobalModule,
    NgxSpinnerModule,
    NgxUploaderModule,
    RouterModule.forRoot(routes),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    NgxKeycloakModule.forRoot()
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initKeycloak, multi: true, deps: [KeycloakService] },
    { provide: APP_BASE_HREF, useValue: '/empresa' }],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function initKeycloak(service: KeycloakService) {
  const env = {
    url: environment.KEYCLOAK_URL,
    clientId: environment.KEYCLOAK_CLIENT,
    realm: environment.KEYCLOAK_REALM
  };

  service.urlsToIgnore = [/cdlrest.procempa.com.br/];
  return () => service.init(env, { onLoad: 'login-required' }).catch(e => {
    console.log(`Module catch error:\n ${JSON.stringify(e)}`);
    throw Error(`Module catch error:\n ${JSON.stringify(e)}`);
  });
}
