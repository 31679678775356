import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter, map, switchMap } from 'rxjs/operators';
import { AlertService } from '../../../../../../global/info-alert/alert.service';
import { VistoriaVO } from '../../../../../../global/models/vistoriaVO';
import { datePickerOptions } from '../../../../../../global/utils/myDatePickerOptions';
import { VistoriaService } from '../../../services/vistoria.service';
import { dateToMyDateModel } from '../../../../../../global/utils/myDateModelConverter';

@Component({
  selector: 'etp-vistoria-edicao',
  templateUrl: './vistoria-edicao.component.html',
  styleUrls: ['./vistoria-edicao.component.scss']
})
export class VistoriaEdicaoComponent implements OnInit {

  constructor(
    private loader: NgxSpinnerService,
    private route: ActivatedRoute,
    private vistoriaService: VistoriaService,
    private alertService: AlertService
  ) {
  }

  vistoria: VistoriaVO = {};
  situacaoVistoriaList: string[];
  tipoVistoriaList: string[];

  ngOnInit() {

    this.vistoriaService.obtemTipoVistoriaList().subscribe(vs => this.tipoVistoriaList = vs);

    this.vistoriaService.obtemSituacaoVistoriaList().subscribe(sts => this.situacaoVistoriaList = sts);

    this.route.paramMap.pipe(
      map(params => params.get('id')),
      filter(id => !isNaN(Number(id)) && Number(id) > 0),
      switchMap(id => this.vistoriaService.obtemVistoriaId(id))
    ).subscribe(vistoria => {
      if (vistoria) {
        this.montaVistoria(vistoria);
      } else {
        this.vistoria = {}
      }
    },
      erro => this.alertService.criaAlerta(`Vistoria id: ${erro}`, 'erro')
    );
  }

  private montaVistoria(vistoria: VistoriaVO) {
    this.vistoria = vistoria;
    let dataVO = new Date(vistoria.dataRealizacao);

    this.vistoria.dataRealizacao = dateToMyDateModel(dataVO);
  }

  persisteVistoria() {
    let acao = this.vistoria.id ? 'alterada' : 'criada';
    this.vistoria.dataRealizacao = this.vistoria.dataRealizacao && this.vistoria.dataRealizacao.jsdate || this.vistoria.dataRealizacao;
    this.loader.show();

    this.vistoriaService.persisteVistoria(this.vistoria).subscribe(
      vistoria => {
        this.montaVistoria(vistoria || this.vistoria);
        this.alertService.criaAlerta(`Vistoria ${acao} com sucesso!`, 'ok');
        window.location.hash = '';
        window.location.hash = 'info-alert';
      },
      erro => {
        this.alertService.criaAlerta(`Erro ao realizar ação. ${erro}`, 'erro')
        this.loader.hide();
      },
      () => this.loader.hide()
    );
  }

}
