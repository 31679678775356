<form class="form-horizontal" #f="ngForm">
  <div class="panel panel-primary">
    <div class="panel-heading">Upload de Equipamentos ou Vistorias</div>
    <div class="panel-body">
      <div class="form-group">
        <label for="inputTipo" class="col-sm-3 control-label">Selecione o tipo de Arquivo</label>
        <div class="col-sm-9">
          <label class="radio-inline">
            <input type="radio" name="tipoArquivo" [(ngModel)]="tipoArquivo" id="inlineRadio1" value="equipamento" (change)='resetarSituacaoEnvio()'>Novos Equipamentos
          </label>
          <label class="radio-inline">
            <input type="radio" name="tipoArquivo" [(ngModel)]="tipoArquivo" id="inlineRadio2" value="vistoria" (change)='resetarSituacaoEnvio()'>Novas Vistorias
          </label>
          <label class="radio-inline">
            <input type="radio" name="tipoArquivo" [(ngModel)]="tipoArquivo" id="inlineRadio2" value="manutencao">Assumir Manutenção
          </label>
          <label class="radio-inline">
            <input type="radio" name="tipoArquivo" [(ngModel)]="tipoArquivo" id="inlineRadio2" value="encerramento">Encerrar Manutenção
          </label>
        </div>
      </div>

      <div class="form-group">
        <div class="col-md-12">
          <br>
          <label class="upload-button">
            <input type="file" ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
          </label>
        </div>
        <div class="col-md-12">
          <p class="text-warning">Somente arquivos em formato CSV e menores que 10MB</p>
          <p *ngIf="tipoArquivo == 'equipamento' || tipoArquivo== 'vistoria' ">
              <b>Você receberá em seu e-mail a situação do processamento após o envio.</b>
              <br>
              <em>Situação do envio: {{situacaoEnvio}}</em>
          </p>
        </div>
      </div>

      <div class="form-group">
        <div class="col-sm-10" *ngIf="hasFile">
          <button type="button" class="btn btn-primary btn pull-right" [disabled]="!tipoArquivo" (click)="startUpload()">
            <span class="glyphicon glyphicon-upload"></span> Upload</button>
        </div>
        <div class="col-sm-02"></div>
      </div>

      <div class="column is-6 is-offset-3" *ngIf="hasFile">
        <div class="upload-file">
          <div class="progress">
            <div role="progressbar" [style.width]="ngxFile.progress.data.percentage + '%'" class="progress-bar-success progress-bar">
              {{ngxFile.progress.data.percentage + '%'}}
            </div>
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="isArquivoValido">
        <div class="col-sm-12" align="center">
          <h2>Upload com sucesso! Processo finalizado.</h2>
        </div>
      </div>
    </div>
  </div>

  <div class="panel panel-primary" *ngIf="isValidacaoComErro()">
    <div class="panel-heading">
      <h3 class="panel-title">Validação do Arquivo enviado</h3>
    </div>
    <div class="panel-body">
      <table class="table table-striped">
        <thead>
          <tr>
            <th colspan="2">Relação de erros</th>
          </tr>
          <tr>
            <th>Mensagem</th>
            <th>Detalhe</th>
          </tr>
        </thead>
        <tbody>
          <tr class="collection-item " *ngFor="let erro of retornoErroListTela ">
            <td>{{erro.mensagem}}</td>
            <td>{{erro.id}}</td>
          </tr>
        </tbody>
      </table>
      <etp-global-paginar *ngIf="!isArquivoValido" [qtdPorPagina]="qtdPorPagina" [totalRegistros]="retornoErroList?.length" (onPaginate)="paginar($event)"></etp-global-paginar>
    </div>
  </div>

</form>

{{ isValidando ? openModal(modalTemplate) : closeModal(modalTemplate) }}

<ng-template #modalTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Validando...</h4>
  </div>
  <div class="modal-body">
    Carregando o arquivo enviado. Por favor aguarde.
  </div>
</ng-template>
