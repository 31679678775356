<form class="form-horizontal" id="form-eqp" #equipamentoForm="ngForm" (ngSubmit)="persisteEquipamento()" novalidate>

  <div class="panel panel-primary">

    <div class="panel-heading">Informações do Equipamento</div>

    <div class="panel-body">
      <fieldset name="equipamentoset" form="form-eqp">
        <legend>Equipamento</legend>

        <div class="form-group">
          <label for="inputCodigoMunicipal" class="col-sm-2 control-label">Código Municipal</label>
          <div class="col-sm-3">
            <input type="number" [(ngModel)]="equipamento.codigoMunicipalFormatado" name="inputCodigoMunicipal" class="form-control"
              id="inputCodigoMunicipal" disabled>
          </div>
        </div>

        <div class="form-group">
          <label for="inputDataInicio" class="col-sm-2 control-label">Período de manutenção inicial</label>
          <div class="col-sm-3">
            <my-date-picker name="inputDataInicio" required [(ngModel)]="contrato.dataInicio" #inputDataInicio="ngModel"></my-date-picker>
            <div *ngIf="inputDataInicio.errors && (inputDataInicio.dirty)" class="alert alert-danger">
              <div [hidden]="!inputDataInicio.errors.required">
                Data de início Obrigatória
              </div>
            </div>
          </div>

          <label for="inputDataFim" class="col-sm-2 control-label">Período de manutenção final</label>
          <div class="col-sm-3">
            <my-date-picker name="inputDataFim" required [(ngModel)]="contrato.dataFim" #inputDataFim="ngModel"></my-date-picker>
            <div *ngIf="inputDataFim.errors && (inputDataFim.dirty)" class="alert alert-danger">
              <div [hidden]="!inputDataFim.errors.required">
                Data de fim Obrigatória
              </div>
            </div>
          </div>
        </div>

        <div class="form-group has-feedback has-warning" *ngIf="mostrarEncerramento()">
          <label for="dataEncerramento" class="col-sm-2 control-label">Data de encerramento</label>
          <div class="col-sm-3">
            <my-date-picker name="dataEncerramento" [(ngModel)]="contrato.dataEncerramento" #dataEncerramento="ngModel"></my-date-picker>
          </div>
          <div class="col-sm-5">
            <div class="alert alert-warning" role="alert">
              <strong>Atenção:</strong> ao informar a data de encerramento o vinculo de manutenção com o equipamento será encerrado
              e o equipamento liberado para outras empresas!
            </div>
          </div>
        </div>

        <div class="form-group">
          <div [ngClass]="aplicaCssErro(inputTipo)">
            <label for="inputTipo" class="col-sm-2 control-label">Tipo</label>
            <div class="col-sm-3">
              <select class="form-control" id="inputTipo" [(ngModel)]="equipamento.tipoEquipamento" name="inputTipo" required #inputTipo="ngModel">
                <option value="" disabled selected>Selecione...</option>
                <option *ngFor="let tipo of tipoEquipamentoList" [value]="tipo">{{tipo}}</option>
              </select>
              <div *ngIf="inputTipo.errors && (inputTipo.dirty)" class="alert alert-danger">
                <div [hidden]="!inputTipo.errors.required">
                  <span class="glyphicon glyphicon-remove form-control-feedback"></span>Tipo Obrigatório
                </div>
              </div>
            </div>
          </div>

          <div [ngClass]="aplicaCssErro(inputTipo)">
            <label for="inputCapacidadeCarga" class="col-sm-2 control-label">Capacidade de Carga</label>
            <div class="col-sm-3">
              <input type="text" [(ngModel)]="equipamento.capacidadeCarga" name="inputCapacidadeCarga" class="form-control" id="inputCapacidadeCarga"
                required placeholder="informe a CAPACIDADE DE CARGA do equipamento" #inputCapCarga="ngModel">
              <div *ngIf="inputCapCarga.errors && (inputCapCarga.dirty)" class="alert alert-danger">
                <div [hidden]="!inputCapCarga.errors.required">
                  <span class="glyphicon glyphicon-remove form-control-feedback"></span>Capacidade Obrigatória
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div [ngClass]="aplicaCssErro(inputTipo)">
            <label for="marca" class="col-sm-2 control-label">Marca</label>

            <div class="col-sm-3">
              <select class="form-control" id="marca" [(ngModel)]="equipamento.marca" name="marca" required #inputMarca="ngModel">
                <option value="" disabled selected>Selecione...</option>
                <option *ngFor="let marca of marcas" [value]="marca">{{marca}}</option>
              </select>
              <div *ngIf="inputMarca.errors && (inputMarca.dirty)" class="alert alert-danger">
                <div [hidden]="!inputMarca.errors.required">
                  <span class="glyphicon glyphicon-remove form-control-feedback"></span>Marca Obrigatória
                </div>
              </div>
            </div>
          </div>

          <div [ngClass]="aplicaCssErro(inputTipo)">
            <label for="numeroFabricante" class="col-sm-2 control-label">Número do fabricante</label>
            <div class="col-sm-3">
              <input type="text" [(ngModel)]="equipamento.serialFabricante" name="numeroFabricante" class="form-control" id="numeroFabricante"
                required placeholder="informe o númerdo de série do equipamento" #inputNumeroFabricante="ngModel">
              <div *ngIf="inputNumeroFabricante.errors && (inputNumeroFabricante.dirty)" class="alert alert-danger">
                <div [hidden]="!inputNumeroFabricante.errors.required">
                  <span class="glyphicon glyphicon-remove form-control-feedback"></span>Número Obrigatório
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="inputObs" class="col-sm-2 control-label">Observações</label>
          <div class="col-sm-8">
            <textarea rows="3" type="text" [(ngModel)]="contrato.observacao" name="inputObs" class="form-control" id="inputObs" placeholder="informe as OBSERVAÇÕES">
            </textarea>
          </div>
        </div>
      </fieldset>

      <fieldset name="responsaveisset" form="form_eqp">
        <legend>Responsáveis</legend>

        <div class="form-group" [ngClass]="aplicaCssErro(inputResponsavelTecnico)">
          <label for="inputRespTec" class="col-sm-2 control-label">Responsável Técnico</label>
          <div class="col-sm-8">
            <input type="text" [(ngModel)]="contrato.responsavelTecnico" name="inputResponsavelTecnico" class="form-control" id="inputResponsavelTecnico"
              required minlength="4" maxlength="80" placeholder="informe o RESPONSÁVEL TÉCNICO" #inputResponsavelTecnico="ngModel">
            <div *ngIf="inputResponsavelTecnico.errors && (inputResponsavelTecnico.dirty)" class="alert alert-danger">
              <div [hidden]="!inputResponsavelTecnico.errors.required">
                <span class="glyphicon glyphicon-remove form-control-feedback"></span>Favor informar o Responsável Técnico!
              </div>
              <div [hidden]="!inputResponsavelTecnico.errors.minlength">
                <span class="glyphicon glyphicon-remove form-control-feedback"></span>O Responsável Técnico deve ter no mínimo 4 caracteres.
              </div>
              <div [hidden]="!inputResponsavelTecnico.errors.maxlength">
                <span class="glyphicon glyphicon-remove form-control-feedback"></span>O Responsável Técnico não pode ter mais de 80 caracteres.
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" [ngClass]="aplicaCssErro(inputArtRespTecnico)">
          <label for="inputArtRespTec" class="col-sm-2 control-label">ART</label>
          <div class="col-sm-8">
            <input type="text" [(ngModel)]="contrato.artRespTecnica" name="inputArtRespTec" class="form-control" id="inputArtRespTec"
              required minlength="4" placeholder="informe a ART" #inputArtRespTecnico="ngModel">
            <div *ngIf="inputArtRespTecnico.errors && (inputArtRespTecnico.dirty)" class="alert alert-danger">
              <div [hidden]="!inputArtRespTecnico.errors.required">
                <span class="glyphicon glyphicon-remove form-control-feedback"></span>Favor informar a Anotação de Responsabilidade Técnica!
              </div>
              <div [hidden]="!inputArtRespTecnico.errors.minlength">
                <span class="glyphicon glyphicon-remove form-control-feedback"></span>A Anotação de Responsabilidade Técnica deve ter no mínimo 4 caracteres.
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" [ngClass]="aplicaCssErro(inputNomeResponsavelEquipamento)">
          <label for="inputRespEquip" class="col-sm-2 control-label">Responsável Equip.</label>
          <div class="col-sm-8">
            <input type="text" [(ngModel)]="contrato.nomeResponsavelEquipamento" name="inputNomeResponsavelEquipamento" class="form-control"
              required minlength="4" maxlength="80" id="inputNomeResponsavelEquipamento" placeholder="informe o NOME DO RESPONSÁVEL PELO EQUIPAMENTO"
              #inputNomeResponsavelEquipamento="ngModel">
            <div *ngIf="inputNomeResponsavelEquipamento.errors && (inputNomeResponsavelEquipamento.dirty)"
              class="alert alert-danger">
              <div [hidden]="!inputNomeResponsavelEquipamento.errors.required">
                <span class="glyphicon glyphicon-remove form-control-feedback"></span>Favor informar o Responsável do Equipamento!
              </div>
              <div [hidden]="!inputNomeResponsavelEquipamento.errors.minlength">
                <span class="glyphicon glyphicon-remove form-control-feedback"></span>O Responsável do Equipamento deve ter no mínimo 4 caracteres.
              </div>
              <div [hidden]="!inputNomeResponsavelEquipamento.errors.maxlength">
                <span class="glyphicon glyphicon-remove form-control-feedback"></span>O Responsável do Equipamento não pode ter mais de 80 caracteres.
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" [ngClass]="aplicaCssErro(inputEmailRespEquip)">
          <label for="inputEmailRespEquip" class="col-sm-2 control-label">Email Resp. Equip.</label>
          <div class="col-sm-8">
            <input type="email" email required [(ngModel)]="contrato.emailResponsavelEquipamento" name="inputEmailRespEquip" class="form-control"
              id="inputEmailRespEquip" placeholder="informe o EMAIL DO RESPONSÁVEL PELO EQUIPAMENTO" #inputEmailRespEquip="ngModel">
            <div *ngIf="inputEmailRespEquip.errors && (inputEmailRespEquip.dirty)" class="alert alert-danger">
              <div [hidden]="!inputEmailRespEquip.errors.required">
                <span class="glyphicon glyphicon-remove form-control-feedback"></span>Favor informar o Email do Responsável do Equipamento!
              </div>
              <div [hidden]="!inputEmailRespEquip.errors.email">
                <span class="glyphicon glyphicon-remove form-control-feedback"></span>Favor informar o Email válido!
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset name="imovelset" form="form_eqp">
        <legend>Endereço</legend>

        <etp-procempa-cdl (onSelecionaEndereco)="selecionaCDL($event)" [logradouroCdl]="cdl"></etp-procempa-cdl>

        <div class="form-group" [ngClass]="aplicaCssErro(inputNomeImovel)">
          <label for="inputComplemento" class="control-label col-sm-2">Complemento</label>
          <div class="col-sm-3">
            <input type="text" class="form-control" id="inputComplemento" name="inputComplemento" [(ngModel)]="equipamento.imovelComplemento">
          </div>

          <label for="inputNomeImovel" class="col-sm-2 control-label">Nome do Imóvel</label>
          <div class="col-sm-3">
            <input type="text" class="form-control" [(ngModel)]="equipamento.imovelNomePredio" name="inputNomeImovel" required id="inputNomeImovel"
              placeholder="informe o nome do imóvel" #inputNomeImovel="ngModel">
            <div *ngIf="inputNomeImovel.errors && (inputNomeImovel.dirty)" class="alert alert-danger">
              <div [hidden]="!inputNomeImovel.errors.required">
                <span class="glyphicon glyphicon-remove form-control-feedback"></span>Favor informar o Nome do Imóvel!
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="inputFrentes" class="col-sm-2 control-label">Frentes</label>
          <div class="col-sm-8">
            <textarea rows="2" type="text" [(ngModel)]="equipamento.imovelFrentes" name="inputFrentes" class="form-control" id="inputFrentes"
              placeholder="informe as frentes com que o imóvel faz divisa">
              </textarea>
          </div>
        </div>
      </fieldset>

    </div>

    <div class="panel-footer clearfix">
      <div class="pull-right">
        <button type="button" class="btn" [routerLink]="['/equipamentos']" routerLinkActive="active">Voltar</button>
        <button type="submit" class="btn btn-primary" [disabled]="!equipamentoForm.form.valid">Salvar</button>

        <ngx-spinner type="ball-clip-rotate-multiple" size="medium">
          <p class="loading">Carregando...</p>
        </ngx-spinner>
      </div>
    </div>

  </div>
</form>
