import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { errorHandler } from '../../../../global/utils/errorhandler';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  constructor(private http: HttpClient) { }

  url = environment.apiUrl + '/empresa';

  verificarCadastroEmpresa() {
    const url = this.url + "/ok";
    return this.http.get<boolean>(url).pipe(catchError(errorHandler));;
  }
}
