import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { LogradouroCdl } from "procempa-cdl-lib";
import { filter, map, switchMap } from "rxjs/operators";
import { AlertService } from "../../../../../../global/info-alert/alert.service";
import { ContratoVO } from "../../../../../../global/models/contratoVO";
import { EquipamentoVO } from "../../../../../../global/models/equipamentoVO";
import { datePickerOptions } from "../../../../../../global/utils/myDatePickerOptions";
import { EquipamentoService } from "../../../services/equipamento.service";
import {dateToMyDateModel,myDateModelToDate} from "../../../../../../global/utils/myDateModelConverter";

@Component({
  selector: "etp-equipamento-edicao",
  templateUrl: "./equipamento-edicao.component.html",
  styleUrls: ["./equipamento-edicao.component.scss"]
})
export class EquipamentoEdicaoComponent implements OnInit {
  equipamento: EquipamentoVO = {};
  contrato: ContratoVO = {};

  tipoEquipamentoList: string[];
  marcas: string[];

  cdl: LogradouroCdl;

  constructor(
    private loader: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private equipamentoService: EquipamentoService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.route.paramMap
      .pipe(
        map(params => params.get("id")),
        filter(id => !isNaN(Number(id)) && Number(id) > 0),
        switchMap(id => this.equipamentoService.obtemEquipamentoIdContrato(id))
      )
      .subscribe(
        contrato => {
          if (contrato) {
            this.montaContrato(contrato);
          }
        },
        e => {
          this.alertService.criaAlerta(`Acesso não permitido ${e}`, "aviso");
          this.router.navigate(["/equipamentos"]);
        }
      );

    this.equipamentoService
      .obtemTipoEquipamentoList()
      .subscribe(tipos => (this.tipoEquipamentoList = tipos));

    this.equipamentoService
      .obtemMarcaEquipamentoList()
      .subscribe(mcs => (this.marcas = mcs));
  }

  private montaContrato(contrato: ContratoVO) {
    this.contrato = contrato;
    this.equipamento = contrato.equipamentoVO;

    if (this.equipamento.imovelCodLogradouro && this.equipamento.imovelNumero) {
      this.cdl = {
        codigoLogradouro: +this.equipamento.imovelCodLogradouro,
        numero: +this.equipamento.imovelNumero
      };
    }

    if (contrato.dataInicio && contrato.dataFim) {
      let dataIni = new Date(contrato.dataInicio);
      let dataFim = new Date(contrato.dataFim);

      this.contrato.dataInicio = dateToMyDateModel(dataIni);
      this.contrato.dataFim = dateToMyDateModel(dataFim);
    }
  }

  persisteEquipamento() {
    this.contrato.equipamentoVO = this.equipamento;
    this.contrato.dataInicio = myDateModelToDate(this.contrato.dataInicio);
    this.contrato.dataFim = myDateModelToDate(this.contrato.dataFim);

    if (this.contrato.dataEncerramento) {
      this.contrato.dataEncerramento = myDateModelToDate(
        this.contrato.dataEncerramento
      );
    }
    let acao = this.contrato.id ? "alterado" : "criado";
    this.loader.show();

    this.equipamentoService.persisteEquipamento(this.contrato).subscribe(
      contrato => {
        this.montaContrato(contrato || this.contrato);
        this.alertService.criaAlerta(`Equipamento ${acao} com sucesso`, "ok");
        window.location.hash = "";
        window.location.hash = "info-alert";
      },
      error => {
        this.alertService.criaAlerta(
          `Erro ao realizar ação - ${error}`,
          "erro"
        );
        this.loader.hide();
      },
      () => this.loader.hide()
    );
  }

  selecionaCDL(cdl: LogradouroCdl) {
    this.cdl = cdl;
    this.equipamento.imovelCodLogradouro = cdl && cdl.codigoLogradouro;
    this.equipamento.imovelNumero =
      cdl && cdl.numero ? cdl.numero.toString() : "";
  }

  mostrarEncerramento() {
    return this.contrato && this.contrato.id;
  }

  aplicaCssErro(campo) {
    return {
      "has-error": this.verificaValidTouched(campo),
      "has-feedback": this.verificaValidTouched(campo)
    };
  }

  private verificaValidTouched(campo) {
    return !campo.valid && campo.touched;
  }
}
