<form class="form-horizontal" #f="ngForm" (ngSubmit)="onSubmit(f)" novalidate>
  <p class="col-md-10">Pesquisa de Vistoria</p>
  <nav class="col-md-2">
    <a [routerLink]="['./nova']" routerLinkActive="active">Nova Vistoria</a>
  </nav>
  <br>
  <br>
  <div class="panel panel-default">
    <div class="panel-heading">Critérios de Pesquisa</div>
    <div class="panel-body">
      <div class="form-group">
        <label for="inputCriterioCodigoMunicipal" class="col-sm-2 control-label">Código Municipal</label>
        <div class="col-sm-4">
          <input type="text" [(ngModel)]="criterioCodigoMunicipal" class="form-control"
            name="inputCriterioCodigoMunicipal" id="idCriterioCodigoMunicipal" placeholder="Código Municipal">
        </div>
      </div>
      <div class="form-group">
        <label for="inputCriterioImovelNomePredio" class="col-sm-2 control-label">Nome do Imóvel</label>
        <div class="col-sm-4">
          <input type="text" [(ngModel)]="criterioImovelNomePredio" class="form-control"
            name="inputCriterioImovelNomePredio" id="idImovelNomePredio" placeholder="Nome do Imóvel">
        </div>
      </div>
      <div class="form-group">
        <label for="inputCriterioDataRealizacao" class="col-sm-2 control-label">Data realização</label>
        <div class="col-sm-4">
          <my-date-picker name="inputCriterioDataRealizacao" [(ngModel)]="criterioDataRealizacao"></my-date-picker>
        </div>
      </div>
    </div>

    <div class="panel-footer clearfix">
      <div class="pull-right">
        <button type="submit " class="btn btn-primary">Pesquisar</button>
        <ngx-spinner type="ball-clip-rotate-multiple" size="medium">
          <p class="loading">Carregando...</p>
        </ngx-spinner>
      </div>
    </div>
  </div>
</form>

<div class="panel panel-primary" [hidden]="isResultadoHidden">
  <div class="panel-heading">
    <h3 class="panel-title">Lista de Equipamento</h3>
  </div>

  <div class="panel-body">
    <table class="table table-striped">
      <thead>
        <tr>
          <th class="col-md-2">Data Realização</th>
          <th class="col-md-2">Código Municipal</th>
          <th class="col-md-2">Nome do Imóvel</th>
          <th class="col-md-2">Tipo da Vistoria</th>
          <th class="col-md-2">Situação do Equipamento</th>
          <th class="col-md-2">Empresa</th>
          <th class="col-md-4">Vistoriador</th>
        </tr>
      </thead>
      <tr class="collection-item " *ngFor="let vistoria of vistoriaListPaginacao ">
        <td>
          <a [routerLink]="[ './', vistoria.id]" [queryParams]="optionalParams">{{ vistoria.dataRealizacao | date:'dd/MM/yyyy' }}</a>
        </td>
        <td>{{ vistoria.codigoMunicipal }}</td>
        <td>{{ vistoria.contrato?.equipamentoVO.imovelNomePredio}}</td>
        <td>{{ vistoria.tipoVistoria }}</td>
        <td>{{ vistoria.situacaoEquipamento }}</td>
        <td>{{ vistoria.contrato?.empresaVO?.nome }}</td>
        <td>{{ vistoria.vistoriador }}</td>
      </tr>
    </table>
    <etp-global-paginar [qtdPorPagina]="qtdPorPagina" [totalRegistros]="vistoriaList.length" [qtdAdjacentes]="3"
      (onPaginate)="paginar($event) "></etp-global-paginar>
  </div>
</div>
