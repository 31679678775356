
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ContratoVO } from '../../../../global/models/contratoVO';
import { errorHandler } from '../../../../global/utils/errorhandler';
import { environment } from '../../environments/environment';

/**
 * Um equipamento é representado por seu contrato
 */
@Injectable({
  providedIn: "root"
})
export class EquipamentoService {

  constructor(private http: HttpClient) {
  }

  API_URL = environment.apiUrl + '/equipamento';

  obtemTipoEquipamentoList(): Observable<string[]> {
    return this.http
      .get<string[]>(this.API_URL + "/tipoEquipamento")
      .pipe(catchError(errorHandler));
  }

  /**
   * Obtem um equipamento com os dados de contrato. Por sua vez, somente empresas
   * com esse contrato podem acessar esse objeto
   *
   * @param id id equipamento
   */
  obtemEquipamentoIdContrato(id: string): Observable<ContratoVO> {
    return this.http.get<ContratoVO>(`${this.API_URL}/detalhe/${id}/contrato/`).pipe(
      catchError(errorHandler));
  }

  obtemMarcaEquipamentoList(): Observable<string[]> {
    return this.http.get<string[]>(`${this.API_URL}/marcasEquipamento`)
      .pipe(catchError(errorHandler));
  }

  persisteEquipamento(contrato: ContratoVO): Observable<ContratoVO> {
    return this.http
      .post<ContratoVO>(this.API_URL, contrato).pipe(
        catchError(errorHandler));
  }

  obtemEquipamentoPesquisa(codigoMunicipal?: number, dataInicio?: Date, dataFim?: Date, codigoLogradouro?: number, numeroLogradouro?: number): Observable<ContratoVO[]> {
    let params = new HttpParams();

    if (codigoMunicipal) {
      params = params.set('cm', codigoMunicipal.toString());
    }

    if (dataInicio) {
      params = params.set('di', dataInicio.toDateString());
    }

    if (dataFim) {
      params = params.set('df', dataFim.toDateString());
    }

    if (codigoLogradouro) {
      params = params.set('cdl', codigoLogradouro.toString());
    }

    if (numeroLogradouro) {
      params = params.set('nm', numeroLogradouro.toString());
    }

    return this.http
      .get<any>(`${this.API_URL}/pesquisaEquipamento`, { params: params }).pipe(
        catchError(errorHandler));
  }

  obtemEquipamentoPesquisaEmpresa(codigoMunicipal?: number, dataInicio?: Date, dataFim?: Date, codigoLogradouro?: number, numeroLogradouro?: number): Observable<ContratoVO[]> {
    let params = new HttpParams();

    if (codigoMunicipal) {
      params = params.set('cm', codigoMunicipal.toString());
    }

    if (dataInicio) {
      params = params.set('di', dataInicio.toDateString());
    }

    if (dataFim) {
      params = params.set('df', dataFim.toDateString());
    }

    if (codigoLogradouro) {
      params = params.set('cdl', codigoLogradouro.toString());
    }

    if (numeroLogradouro) {
      params = params.set('nm', numeroLogradouro.toString());
    }

    return this.http
      .get<any>(`${this.API_URL}/pesquisaEquipamento/empresa`, { params: params }).pipe(
        catchError(errorHandler));
  }

  obtemEquipamentoPesquisaSemContrato(codigoMunicipal: number, codigoLogradouro: number, numeroLogradouro: string): Observable<ContratoVO[]> {
    let params = new HttpParams();

    if (codigoMunicipal) {
      params = params.set('cm', codigoMunicipal.toString());
    }

    if (codigoLogradouro) {
      params = params.set('cdl', codigoLogradouro.toString());
    }

    if (numeroLogradouro) {
      params = params.set('nm', numeroLogradouro.toString());
    }

    return this.http
      .get<any>(`${this.API_URL}/pesquisaEquipamento/disponivel`, { params: params }).pipe(
        catchError(errorHandler));
  }

}
